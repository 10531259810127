import React, { useState, useRef, useEffect, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';

import Modal from "./Modal";
import Text from "./base/Text";
import Divider from "./base/Divider";
import Document, { DocumentType } from "./Document";
import Button from "./base/Button";
import { addDocument, retrieveDocumentOptions } from "../requests";
import { useCookies } from "react-cookie";
import AISearch from "./AISearch";
import Logo from "./Logo";
import Space from "./base/Space";

const MenuModal = ({ setModalOpen, modalOpen }: { 
  setModalOpen: (b: boolean) => void,
  modalOpen: boolean,
}) => {
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);
  const [documents, setDocuments] = useState<DocumentType[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<DocumentType | null>(null);
  const [selectedProperty, setSelectedProperty] = useState<string | null>(null);
  const [loadMoreClicks, setLoadMoreClicks] = useState<number>(0);
  const navigate = useNavigate();

  return (
    <Modal setModalOpen={setModalOpen} modalOpen={modalOpen} id="menuModal" extraStyles={{
      padding: "40px",
      //boxShadow: "0 8px 6px -6px gray, -3px 0 6px -6px gray, 3px 0 6px -6px gray",
      width: "100%",
      height: "100%",
      backgroundColor: "#0f1434",
      overflowY: "scroll"
    }}>
      <Space px={20} />
      <Logo />
      <Space px={120} />
      <div style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px"
      }}>
        <Text size={30} weight={600} color="white" onClick={() => window.location.replace(process.env.REACT_APP_BASE_URL ?? "")}>
          Home
        </Text>
        <Link to="#how-it-works" style={{ textDecoration: 'none' }}><Text size={30} weight={600} color="white" onClick={() => setModalOpen(false)}>
          How it works
        </Text></Link>
        <Link to="/careers" style={{ textDecoration: 'none' }}><Text size={30} weight={600} color="white" onClick={() => setModalOpen(false)}>
          Careers
        </Text></Link>
        <Space px={15} />
        <Button color="#4455F3" width="150px" height="50px" onClick={() => window.open("https://calendly.com/matthewfastow/coverflow-demo", "_blank")}>
          <Text size={14} color="white">
            Request Demo
          </Text>
        </Button>
      </div>
      <img
        src={process.env.PUBLIC_URL + "/assets/x.png" }
        style={{ position: "fixed", width: "20px", top: "55px", right: "40px", cursor: "pointer" }} 
        onClick={() => setModalOpen(false)}
      />

    </Modal>
  )
};

export default MenuModal;
