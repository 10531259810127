import React, { useState, useRef, useEffect, ReactNode } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import { useCookies } from 'react-cookie';
import { Analytics } from '@vercel/analytics/react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tooltip } from 'react-tooltip';

import Sidebar from "./components/Sidebar";
import AppHeader from "./components/AppHeader";
import BottomBar from "./components/BottomBar";
import LoadingSpinner from "./components/base/Spinner";
import PrivacyModal from "./components/PrivacyModal";
import TermsModal from "./components/TermsModal";

import Spacer from "./components/base/Spacer";
import Space from "./components/base/Space";
import Text from "./components/base/Text";
import "./LandingPage.css";
import ImageContainer from "./components/ImageContainer";
import AppFooter from "./components/AppFooter";
import MenuModal from "./components/MenuModal";
import Button from "./components/base/Button";
import MovingScroll from "./components/MovingScroll";
import Container from "./components/Container";
import EmployeeCard from "./components/EmployeeCard";
import QuickFeature from "./components/QuickFeature";
import CheckFeature from "./components/CheckFeature";
import Logo from "./components/Logo";

//             Measure your workflows in minutes, not hours or days

// test change 2
const integrationWords = ['Applied Epic', 'Vertafore AMS360', 'Gmail', 'Outlook'];

const Careers = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);
  const [searchParams, setSearchParams] = useSearchParams();

  const [page, setPage] = useState<string>("overview");
  const [clientId, setClientId] = useState<string | null>(null);
  const [privacyModalOpen, setPrivacyModalOpen] = useState<boolean>(false);
  const [menuModalOpen, setMenuModalOpen] = useState<boolean>(false);
  const [termsModalOpen, setTermsModalOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<ReactNode | null>(null);
  const [successMessage, setSuccessMessage] = useState<ReactNode | null>(null);
  const [isEngineerOpen, setIsEngineerOpen] = useState<boolean>(false);
  const [isGrowthOpen, setIsGrowthOpen] = useState<boolean>(false);

  const [isPortrait, setIsPortrait] = useState<boolean>(false);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [fadeIn, setFadeIn] = useState<boolean>(false);
  const [currentWordIndex, setCurrentWordIndex] = useState<number>(0);
  const navigate = useNavigate();

  useEffect(() => {
    // Set a delay before starting the fade-in animation
    const timer = setTimeout(() => {
      setFadeIn(true);
    }, 200); // 1-second delay

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);


  useEffect(() => {
    const checkOrientation = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };
    checkOrientation();
    window.addEventListener('resize', checkOrientation);
    return () => window.removeEventListener('resize', checkOrientation);
  }, []);

  const handleScroll = (e) => {
    const { scrollTop } = e.target;
    setScrollPosition(scrollTop);
    //console.log("SCROLL POSITION IS NOW ");
    //console.log(scrollTop);
  };

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage, {
        position: "top-center",
        closeOnClick: true,
        autoClose: false,
        pauseOnHover: true,
        draggable: true,
      });
    }

  }, [errorMessage])

  useEffect(() => {
    if (successMessage) {
      toast.info(successMessage, {
        position: "top-center",
        closeOnClick: false,
        autoClose: false,
        pauseOnHover: true,
        draggable: true,
      });
    }

  }, [successMessage])

  return (
    <div className="landingPageBackground" onScroll={handleScroll}>
      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        //position: "fixed",
        width: "calc(100% - 100px)",
        height: "100px",
        padding: "0px 50px",
        //top: 0,
        //left: 0,
        zIndex: 5,
      }}>
        <div style={{ transform: isPortrait ? "translateX(-20px)" : undefined }}>
          <Logo />
        </div>
        { isPortrait && (
          <img src={process.env.PUBLIC_URL + "/assets/menu.png"} style={{ width: "35px", height: "35px", cursor: "pointer" }} onClick={() => setMenuModalOpen(true)} /> 
        )}
        { !isPortrait && (
          <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "20px",
          }}>
            <Link to="/" style={{ textDecoration: 'none' }}><Text size={16} weight={600} onClick={() => document.getElementById('how-it-works')?.scroll({behavior: 'smooth'})}>
              How it works
            </Text></Link>
            <Link to="/careers" style={{ textDecoration: 'none' }}><Text size={16} weight={600} onClick={() => document.getElementById('how-it-works')?.scroll({behavior: 'smooth'})}>
              Careers
            </Text></Link>
            <Button color="#4455F3" width="150px" height="50px" onClick={() => window.open("https://calendly.com/matthewfastow/coverflow-demo", "_blank")}>
              <Text size={14} color="white">
                Request Demo
              </Text>
            </Button>
          </div>
        )}
      </div>
      <div style={{ 
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        margin: "0% 20%"
      }}>
        <Text size={40} weight={600} align="center">
          Open Roles
        </Text>
        <Space px={20} />
        <div style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          cursor: "pointer"
        }} onClick={() => setIsEngineerOpen(!isEngineerOpen)}>        
          <Text size={30} weight={600}>
            Founding Engineer
          </Text>
          <img src={process.env.PUBLIC_URL + "/assets/forward.png"} style={{ width: "35px", height: "35px", rotate: isEngineerOpen ? "90deg" : undefined }} /> 
        </div>
        { isEngineerOpen && (
          <>
            <Space px={5} />
            <Text size={20} weight={600}>
              Background:
            </Text>
            <Text size={16}>
              The insurance brokerage industry is a $310 billion business that still primarily functions through emailed documents and policies. Every year, billions are spent processing these documents, inputting information into business systems, and filing paper work.
            </Text>
            <Space px={5} />
            <Text size={20} weight={600}>
              Who We Are:
            </Text>
            <Text size={16}>
              Coverflow is a seed stage AI company on a mission to eliminate these time-consuming document workflows for every broker. Our platform currently processes hundreds of insurance policies daily and is growing rapidly with a 6 figure run rate. We’re a young, energetic, and lean team that met at Princeton, and since, have led eng teams at Roblox and Robust Intelligence (acquired by Cisco, 400M). We’re backed by Afore Capital and are looking for a founding engineer to help build an amazing product.
            </Text>
            <Space px={5} />
            <Text size={16}>
              As a founding engineer you’ll:
            </Text>
            <ul style={{ color: "white" }}>
              <li>
                <Text size={16}>
                  Push the boundaries of what's possible with generative technology 
                </Text>
              </li>
              <li>
                <Text size={16}>
                  Dive deep into insurance problems
                </Text>
              </li>
              <li>
                <Text size={16}>
                  Iterate with customers
                </Text>
              </li>
              <li>
                <Text size={16}>
                  Be a part of a strong, passionate, dedicated team
                </Text>
              </li>
            </ul>
            <Text size={16}>
              What we’re looking for:
            </Text>
            <ul style={{ color: "white" }}>
              <li>
                <Text size={16}>
                  2+ years experience in Python, React
                </Text>
              </li>
              <li>
                <Text size={16}>
                  Demonstrated experience and/or deep interest in building generative AI products
                </Text>
              </li>
              <li>
                <Text size={16}>
                  Experience shipping 0→1 products or features, and demonstrated ability to do rapid prototyping
                </Text>
              </li>
              <li>
                <Text size={16}>
                  Ability to move across the stack quickly while balancing quality
                </Text>
              </li>
              <li>
                <Text size={16}>
                  Determined and willing to learn like crazy
                </Text>
              </li>
            </ul>
            <Text size={16}>
              To apply, submit your resume <a href="https://bcovf7hjq8v.typeform.com/to/kDLCmCD3">here</a>
            </Text>
          </>
        )}
      </div>
      <Space px={400} />
      <AppFooter isPortrait={isPortrait} />
      <TermsModal setModalOpen={setTermsModalOpen} modalOpen={termsModalOpen} />
      <PrivacyModal setModalOpen={setPrivacyModalOpen} modalOpen={privacyModalOpen} />
      <MenuModal setModalOpen={setMenuModalOpen} modalOpen={menuModalOpen} />
      <ToastContainer />
      <Analytics />
    </div>
  )
};

export default Careers;
